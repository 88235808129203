<template>
    <input :class="className" type="text" v-model="currentValue" @input="handleInput()" @keydown="handleKeyDown()" :required="isRequired" :placeholder="placeHolder" />
</template>
<script>
export default {
    props: {
      value: {
        type: [String, Number],
        default: "",
      },
      className: {
        type: [String],
        default: "",
      },
      placeHolder: {
        type: [String],
        default: "",
      },
      isRequired: {
        type: [Boolean],
        default: false,
      },
    },
    data() {
        return {
            currentValue: ''
        }
    },
    watch: {
      value: {
        handler(after) {
          this.currentValue = this.format(after)
        },
        immediate: true
      }
    },
    methods: {
        format: value => (value + '').replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),
        handleInput() {
            this.currentValue = this.format(this.currentValue)
            this.$emit('input', (this.currentValue + '').replace(/[^0-9]/g, ""))
        },
        handleKeyDown() {
            this.$emit('keydowns', true)
        }
    }
    
}
</script>