<template>
    <div class="app-footer border-0 shadow-lg">
        <router-link to="/notification" class="nav-content-bttn"><i class="feather-bell"></i></router-link>
        <router-link to="/message" class="nav-content-bttn" data-tab="chats"><i class="feather-message-square"></i></router-link>            
        <router-link to="/" class="nav-content-bttn nav-center"><i class="feather-home"></i></router-link>
        <router-link to="/my-course" class="nav-content-bttn nav-center"><i class="feather-layers"></i></router-link>
        <a href="javascript:void(0)" class="nav-content-bttn sidebar-layer btn-toggle-sidebar"><img :src="user && user.photo ? user.photo : '/images/profile.png'" alt="user" class="w30 shadow-xss rounded-circle btn-toggle-sidebar" @click="toggleSidebar()"></a>
    </div>
</template>

<script>
export default ({
    props: ['user'],
    name: 'AppFooter',
    data() {
        return {
            media : process.env.VUE_APP_URL_CLOUD,
        }
    },
    methods: {
        toggleSidebar(){
            this.$emit('button-sidebar-clicked')
        },
    }
})
</script>
