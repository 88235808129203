<template>
    <modal name="topup-modal"
        :adaptive="true"
        :height="'auto'"
        :clickToClose="false"
        >
        <div class="container-fluid p-0">
            <div class="card" v-if="!isLoad">
                <div class="card-header py-3 bg-white border-0">
                    <a @click="closeModal()" href="javascript:void(0)" class="float-right"><i class="fas fa-times text-danger"></i></a>
                    <h1 class="fredoka-font ls-3 fw-700 text-current-eling font-lg mb-0">Elingway</h1>
                </div>
                <div class="card-body py-3">
                    <div class="row" v-if="step == 1">
                        <div class="form-group col-12">
                            <label for="" class="text-black fw-600">Top Up Amount <small v-if="amount > 500000" class="text-danger fw-500">*( Maximum : Rp. 500.000 )</small></label>
                            <InputCurrency :className="'form-control'" v-model="amount" :value="amount" @keydowns="selected_amount = false" :place-holder="'Insert your top up amount'"></InputCurrency>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-lg-4 col-6 mb-2">
                                    <button @click="selectAmount(10000)" :class="{'active': selected_amount == true && amount == 10000}" class="btn btn-outline-current text-current btn-block fw-500">Rp. 10.000</button>
                                </div>
                                <div class="col-lg-4 col-6 mb-2">
                                    <button @click="selectAmount(25000)" :class="{'active': selected_amount == true && amount == 25000}" class="btn btn-outline-current text-current btn-block fw-500">Rp. 25.000</button>
                                </div>
                                <div class="col-lg-4 col-6 mb-2">
                                    <button @click="selectAmount(50000)" :class="{'active': selected_amount == true && amount == 50000}" class="btn btn-outline-current text-current btn-block fw-500">Rp. 50.000</button>
                                </div>
                                <div class="col-lg-4 col-6 mb-2">
                                    <button @click="selectAmount(75000)" :class="{'active': selected_amount == true && amount == 75000}" class="btn btn-outline-current text-current btn-block fw-500">Rp. 75.000</button>
                                </div>
                                <div class="col-lg-4 col-6 mb-2">
                                    <button @click="selectAmount(100000)" :class="{'active': selected_amount == true && amount == 100000}" class="btn btn-outline-current text-current btn-block fw-500">Rp. 100.000</button>
                                </div>
                                <div class="col-lg-4 col-6 mb-2">
                                    <button @click="selectAmount(150000)" :class="{'active': selected_amount == true && amount == 150000}" class="btn btn-outline-current text-current btn-block fw-500">Rp. 150.000</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button @click="step += 1" class="btn btn-info fw-600" :class="{'disabled': amount < 5000 || amount > 500000}" :disabled="amount < 5000 || amount > 500000">Next<i class="fas fa-forward ml-2 font-xsss"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="step == 2">
                        <div class="col-12 text-center mb-3">
                            <p class="font-md text-current fw-600 mb-0">Payment Method</p>
                        </div>
                        <div class="col-12">
                            <div class="row px-2">
                                <div v-if="menu.va.length !== 0" class="col-12 border pt-3 cursor-pointer" @click="resetMenu('va')">
                                    <a href="javascript:void(0)" class="float-right"><i class="fas" :class="{'fa-angle-down': !menu.va, 'fa-angle-up': menu.va}"></i></a>
                                    <span class="fw-600 text-black font-xsss"><i class="fas fa-money-check mr-2"></i>Virtual Account</span>
                                    <div class="mb-3"></div>
                                </div>
                                <div class="col-12 border" v-if="menu.va">
                                    <div class="w-100 scrolled">
                                        <div v-for="(item, index) in method.va" :class="{'border-top': index != 0}" :key="index" class="row py-3 border-top cursor-pointer" @click="bank_code = item.code; payment_type = item.type;fee = item.fee;type_fee = item.type_fee">
                                            <div class="col-1 text-right p-0">
                                                <i v-if="bank_code == item.code" class="fas fa-check font-xsss"></i>
                                            </div>
                                            <div class="col-7 d-flex flex-column justify-content-center">
                                                <span class="font-xssss text-black fw-600">{{ item.name }}</span>
                                                <span v-if="item.type_fee == 'AMOUNT'" class="font-xssss text-black"> Fee : Rp. {{ formatPrice(item.fee) }}</span>
                                                <span v-else class="font-xssss text-black"> Admin Fee : {{ item.fee }}%</span>
                                            </div>
                                            <div class="col-3 text-right">
                                                <img :src=" item.image_url" height="20px" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="menu.ewallet.length !== 0" class="col-12 border pt-3 cursor-pointer" @click="resetMenu('ewallet')">
                                    <a href="javascript:void(0)" class="float-right"><i class="fas" :class="{'fa-angle-down': !menu.ewallet, 'fa-angle-up': menu.ewallet}"></i></a>
                                    <span class="fw-600 text-black font-xsss"><i class="fas fa-wallet mr-2"></i>E-Wallet</span>
                                    <div class="mb-3"></div>
                                </div>
                                <div class="col-12 border" v-if="menu.ewallet">
                                    <div class="w-100 scrolled">
                                        <div v-for="(item, index) in method.ewallet" :class="{'border-top': index != 0}" :key="index" class="row py-3 border-top cursor-pointer" @click="bank_code = item.code; payment_type = item.type;fee = item.fee;type_fee = item.type_fee">
                                            <div class="col-1 text-right p-0">
                                                <i v-if="bank_code == item.code" class="fas fa-check font-xsss"></i>
                                            </div>
                                            <div class="col-7 d-flex flex-column justify-content-center">
                                                <span class="font-xssss text-black fw-600">{{ item.name }}</span>
                                                <span v-if="item.type_fee == 'AMOUNT'" class="font-xssss text-black"> Fee : Rp. {{ formatPrice(item.fee) }}</span>
                                                <span v-else class="font-xssss text-black"> Admin Fee : {{ item.fee }}%</span>
                                            </div>
                                            <div class="col-3 text-right">
                                                <img :src=" item.image_url" height="20px" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="menu.qr.length !== 0" class="col-12 border pt-3 cursor-pointer" @click="resetMenu('qr')">
                                    <a href="javascript:void(0)" class="float-right"><i class="fas" :class="{'fa-angle-down': !menu.qr, 'fa-angle-up': menu.qr}"></i></a>
                                    <span class="fw-600 text-black font-xsss"><i class="fas fa-qrcode mr-2"></i>QR</span>
                                    <div class="mb-3"></div>
                                </div>
                                <div class="col-12 border" v-if="menu.qr">
                                    <div class="w-100 scrolled">
                                        <div v-for="(item, index) in method.qr" :class="{'border-top': index != 0}" :key="index" class="row py-3 cursor-pointer" @click="bank_code = item.code; payment_type = item.type;fee = item.fee;type_fee = item.type_fee">
                                            <div class="col-1 text-right p-0">
                                                <i v-if="bank_code == item.code" class="fas fa-check font-xsss"></i>
                                            </div>
                                            <div class="col-7 d-flex flex-column justify-content-center">
                                                <span class="font-xssss text-black fw-600">{{ item.name }}</span>
                                                <span v-if="item.type_fee == 'AMOUNT'" class="font-xssss text-black"> Fee : Rp. {{ formatPrice(item.fee) }}</span>
                                                <span v-else class="font-xssss text-black"> Admin Fee : {{ item.fee }}%</span>
                                            </div>
                                            <div class="col-3 text-right">
                                                <img :src=" item.image_url" height="20px" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <div class="row">
                                <div class="col-6">
                                    <button @click="step -= 1" class="btn btn-outline-info fw-600"><i class="fas fa-backward mr-2 font-xsss"></i>Previous</button>
                                </div>
                                <div class="col-6 text-right">
                                    <button @click="bank_code == 'ID_OVO' && payment_type == 'E-Wallet' ? step += 1 : onProcess()" v-if="!process" class="btn btn-info fw-600" :class="{'disabled': !bank_code}" :disabled="!bank_code">Process<i class="fas fa-forward ml-2 font-xsss"></i></button>
                                    <button v-else class="btn btn-info fw-600 disabled" disabled>
                                        <div class="spinner-border spinner-border-sm" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        Process...
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form @submit.prevent="onProcess()" class="row justify-content-center" v-if="step == 3">
                        <div class="col-12 text-center">
                            <p class="font-md text-current fw-600 mb-0">Verification</p>
                            <span class="font-xsss text-black fw-500">Please insert your OVO phone number!</span>
                            <hr>
                        </div>
                        <div class="col-12 text-center py-2">
                            <div class="form-group icon-input mb-1">
                                <span class="font-xsss fw-600 text-grey-700 pr-0">+62</span>
                                <input type="tel" min="11" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$" required class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="Your Phone Number" v-model="phone">                        
                            </div>
                            <p class="font-xsss text-grey-700 fw-500 mb-2">Make sure your OVO Phone Number is correct!</p>
                        </div>
                        <div class="col-12 mt-4">
                            <div class="row">
                                <div class="col-6">
                                    <button @click="step -= 1" class="btn btn-outline-info fw-600"><i class="fas fa-backward mr-2 font-xsss"></i>Previous</button>
                                </div>
                                <div class="col-6 text-right">
                                    <button v-if="!process" class="btn btn-info fw-600" :class="{'disabled': !bank_code}" :disabled="!bank_code">Process<i class="fas fa-forward ml-2 font-xsss"></i></button>
                                    <button v-else class="btn btn-info fw-600 disabled" disabled>
                                        <div class="spinner-border spinner-border-sm" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        Process...
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="row" v-if="step == 4 && transaction">
                        <div class="col-12 text-center">
                            <p class="font-md text-current fw-600 mb-0">Invoice</p>
                            <span v-if="transaction.status == 'PENDING'" class="font-xsss text-black fw-500">Please process your payment before expired!</span>
                            <span v-if="transaction.status == 'FAILED'" class="font-xsss text-black fw-500">Your payment has been failed!</span>
                            <span v-if="transaction.status == 'SUCCESS'" class="font-xsss text-black fw-500">Your payment has been success, Thanks!</span>
                            <hr>
                        </div>
                        <div class="col-12 text-center pb-4">
                            <img :src=" transaction.payment_method_image" height="30px" alt=""><br>
                            <span class="text-success display1-size fw-700" v-if="transaction.method.capture_amount">Rp. {{ transaction.method.capture_amount | numFormat('0,0') }}</span><br v-if="transaction.method.capture_amount">
                            <span class="text-success display1-size fw-700" v-if="transaction.method.expected_amount">Rp. {{ transaction.method.expected_amount | numFormat('0,0') }}</span><br v-if="transaction.method.expected_amount">
                            <span class="text-success display1-size fw-700" v-if="transaction.method.amount">Rp. {{ transaction.method.amount | numFormat('0,0') }}</span><br v-if="transaction.method.amount">
                            <span class="font-xss fw-600 mb-0" :class="{'text-success': transaction.status == 'SUCCESS', 'text-warning': transaction.status == 'PENDING', 'text-danger': transaction.status == 'FAILED'}">STATUS : {{ transaction.status }}</span><br>
                            <button @click="getCurrentTransactionById(transaction.id)" class="btn btn-sm btn-outline-secondary fw-600 px-4 mb-4" :disabled="checkLoad" :class="{'disabled': checkLoad}">
                                <span v-if="!checkLoad">
                                    Check Your Payment Status
                                </span>
                                <div v-else class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                            <div class="w-100 mb-3" v-if="transaction.method.actions && transaction.method.actions.desktop_web_checkout_url && transaction.status == 'PENDING'">
                                <p class="font-xsss text-grey-700 fw-500 mb-2">Click Button below to process your payment</p>
                                <a target="_blank" :href="transaction.method.actions.desktop_web_checkout_url" class="btn btn-primary fw-600 px-5">Process</a>
                            </div>
                            <div class="w-100 mb-3" v-if="transaction.method.qr_string && transaction.status == 'PENDING'">
                                <p class="font-xsss text-grey-700 fw-500 mb-2">Scan QRCode below to process your payment with {{ transaction.payment_method_name }}</p>
                                <qrcode-vue :value="transaction.method.qr_string" level="H" render-as="svg" size="200"  />
                            </div>
                            <div class="w-100 mb-3" v-if="transaction.virtual_account && transaction.status == 'PENDING'">
                                <p class="font-xsss text-grey-700 fw-500 mb-1">Copy Virtual Account below to process your payment</p>
                                <p class="font-xs text-info fw-600 mb-2">{{ transaction.virtual_account }} <a href="javascript:void(0)" class="ml-2 font-xss" @click="doCopy(transaction.virtual_account)"><i class="fas fa-clipboard"></i></a></p>
                            </div>
                            <p class="font-xsss text-black fw-500 mb-2" v-if="transaction.status == 'PENDING'">Click <a href="javascript:void(0)" @click="cancelPayment(transaction.transaction_code)" class="text-danger fw-500">Cancel</a> if you want cancel cancel your payment</p>
                            <p class="font-xsss text-black fw-500 mb-2" v-else>Click <a href="javascript:void(0)" @click="resetModal()" class="text-success fw-500">Top Up</a> if you want top up wallet again</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card py-5" v-else>
                <div class="row py-5">
                    <div class="col-12 text-center">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import axios from 'axios'
import QrcodeVue from 'qrcode.vue'
import InputCurrency from './InputCurrency.vue'
export default {
    components: {
      QrcodeVue,
      InputCurrency
    },
    props: {
        paymentStatus: Number
    },
    data() {
        return {
            isLoad: true,
            checkLoad: false,
            process: false,
            media : process.env.VUE_APP_URL_CLOUD,
            step: 1,
            amount: '',
            selected_amount: false,
            bank_code: '',
            fee: '',
            type_fee: '',
            payment_type: '',
            phone: '',
            menu: {
                va: false,
                ewallet: false,
                qr: false
            },
            method: {
                va: [],
                ewallet: [],
                qr: [],
            },
            transaction: null
        }
    },
    created() {
        this.getMethod()
    },
    watch: {
        paymentStatus:function() {
            this.getCurrentTransactionById(this.transaction.id)
        }
    },
    methods: {
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        resetModal() {
            this.transaction = null
            this.amount = ''
            this.selected_amount = false
            this.bank_code = ''
            this.phone = ''
            this.payment_type = ''
            this.menu = {
                va: false,
                ewallet: false,
                qr: false
            },
            this.step = 1
        },
        resetMenu(type) {
            if (type == 'va') {
                this.menu.ewallet = false;
                this.menu.qr = false;
                this.menu.va = !this.menu.va;
            } else if (type == 'ewallet') {
                this.menu.va = false;
                this.menu.qr = false;
                this.menu.ewallet = !this.menu.ewallet
            } else if (type == 'qr') {
                this.menu.va = false;
                this.menu.ewallet = false;
                this.menu.qr = !this.menu.qr
            }
        },
        closeModal(){
            this.$modal.hide('topup-modal')
        },
        selectAmount(val) {
            this.amount = val
            this.selected_amount = true
        },
        doCopy(text) {
            this.$copyText(text).then(() => {
                this.$swal({
                    toast: true,
                    title: 'Clipboard',
                    text: 'Copied!',
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                })
            })
        },
        async getMethod(){
            let user = JSON.parse(localStorage.getItem('user'))
            if(user.paymentMethod){
                this.method = user.paymentMethod
            }else{
                await axios.get(`${process.env.VUE_APP_URL_API}/account/topup/payment-method`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.method = res.data.data
                    user.paymentMethod = res.data.data
                    localStorage.setItem('user', JSON.stringify(user))
                }).catch((err) => console.error(err.response))
            }
        },
        async onProcess() {
            this.process = true
            let url = '';
            let data = {};
            url = 'create-bill'
            data = {
                'amount': parseInt(this.amount),
                'bank_code': this.bank_code,
                'payment_type': this.payment_type.toLowerCase(),
                'fee': this.fee,
                'type_fee': this.type_fee
            }
            // if(this.payment_type == 'VA') {
            //     url = 'confirm-topup'
            //     data = {
            //         'amount': parseInt(this.amount),
            //         'bank_code': this.bank_code,
            //         'phone': `+62${this.phone}`
            //     }
            // } else if (this.payment_type == 'E-Wallet') {
            //     url = 'confirm-topup/ewallet'
            //     data = {
            //         'amount': parseInt(this.amount),
            //         'ewallet_code': this.bank_code,
            //         'phone': `+62${this.phone}`
            //     }
            // } else if (this.payment_type == 'QR') {
            //     url = 'create-qr'
            //     data = {
            //         'amount': parseInt(this.amount),
            //         'payment_code': this.bank_code,
            //         'phone': `+62${this.phone}`
            //     }
            // }
            await axios.post(`${process.env.VUE_APP_URL_API}/account/topup/${url}`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    window.open(
                        res.data.data.payment_url,
                        '_blank'
                    );
                    // this.getCurrentTransaction();
                    this.$swal({
                        toast: true,
                        title: 'Topup',
                        text: 'Lets finish your payment',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.resetModal();
                } else {
                    this.$swal({
                        toast: true,
                        title: 'TopUp',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                }
            }).catch((err) => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Article',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).finally(() => this.process = false)
        },
        async cancelPayment(transaction_code) {
            let data = {
                'transaction_code': transaction_code
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/account/topup/cancel-transaction`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.$swal({
                        toast: true,
                        title: 'TopUp',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.resetModal()
                } else {
                    this.$swal({
                        toast: true,
                        title: 'TopUp',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                }
            }).catch((err) => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Article',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).finally(() => this.process = false)
        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    ::v-deep .vm--modal {
        width: 90% !important;
        left: 5% !important;
    }
    .scrolled {
        max-height: 200px !important;
    }
}
::v-deep .vm--modal {
    width: 600px;
    height: fit-content !important;
}
.min-vh-50 {
    min-height: 50vh;
}
.btn-outline-current.active {
    color: #fff !important
}
.btn-outline-current:hover {
    color: #fff !important
}
.scrolled {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}
.scrolled::-webkit-scrollbar {
  width: 6px;
  border-radius: 5px;
}

/* Track */
.scrolled::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
.scrolled::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.scrolled::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.form-group.icon-input span {
    position: absolute;
    left: 15px;
    top: 18.5px;
}
.form-group.icon-input input {
    border: 1px solid #fbaf03;
}
</style>