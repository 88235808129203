<template>
    <div>
        <div v-click-outside="config" class="middle-sidebar-right right-scroll-bar" v-bind:class="{'active-sidebar': isSidebarActive}">
            <div class="middle-sidebar-right-content">
                <div class="card overflow-hidden subscribe-widget p-3 mb-3 rounded-xxl border-0">
                    <div class="card-body p-2 d-block text-center bg-no-repeat bg-image-topcenter" style="background-image: url(images/user-pattern.png);">
                        <a href="javascript:void(0)" @click="$emit('close-sidebar', true)" class="position-absolute right-0 mr-4"><i class="feather-x text-danger font-xs"></i></a>
                        <figure v-if="user" class="avatar ml-auto mr-auto mb-0 mt-2 w90">
                            <router-link title="Profile" to="/profile">
                                <img :src="user.photo" alt="image" class="float-right shadow-sm rounded-circle w-100">
                            </router-link>
                        </figure>
                        <figure v-else class="avatar ml-auto mr-auto mb-0 mt-2 w90">
                            <router-link title="Profile" to="/profile">
                                <img :src="'/images/profile.png'" alt="image" class="float-right shadow-sm rounded-circle w-100">
                            </router-link>
                        </figure>
                        <div class="clearfix"></div>
                        <h2 class="text-black font-xss lh-3 fw-700 mt-3 mb-1">{{user ? user.name : 'Loading...'}}</h2>
                        <h4 class="text-grey-500 font-xssss mt-0">@{{user ? user.username : 'Loading...'}}</h4>
                        <div class="clearfix"></div>
                        <div class="col-12 text-center mt-4 mb-2">
                            <router-link title="Admin Page" to="/admin" v-if="isAdmin" class="p-0 ml-1 btn btn-round-md rounded-xl bg-lightblue"><i class="text-current ti-dashboard font-sm"></i></router-link>
                            <router-link title="Change Password" to="/change-password" class="p-0 ml-1 btn btn-round-md rounded-xl bg-lightblue"><i class="text-current ti-lock font-sm"></i></router-link>
                            <a href="javascript:void(0)" @click="onLogout()" class="p-0 btn p-2 lh-24 w100 ml-1 ls-3 d-inline-block rounded-xl bg-danger font-xsssss fw-700 ls-lg text-white">Log Out</a>
                        </div>
                        <hr>
                        <div class="col-12 pl-0 mt-4 text-left">
                            <a href="javascript:void(0)" @click="getWallet()" class="text-grey-600 font-xssss fw-500 float-right" title="Refresh My Wallet"><i class="fas fa-sync"></i></a>
                            <h4 class="text-grey-800 font-xsss fw-700">My Wallet</h4>
                            <div class="row" v-if="!loadWallet">
                                <div class="col-12" v-if="wallet.id">
                                    <div class="row">
                                        <div class="col-12">
                                            <a href="javascript:void(0)" class="font-xsss text-success fw-600"><i class="fas fa-wallet mr-2"></i>Wallet : Rp. {{wallet.balance == 0 ? 0 : wallet.balance | numFormat('0,0')}}</a>
                                        </div>
                                        <div class="col-12">
                                            <a href="javascript:void(0)" class="font-xsss text-info fw-600"><i class="fas fa-certificate mr-2"></i>Point : {{wallet.balance_point | numFormat('0,0')}}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-3" v-if="isRegistered">
                                    <a href="javascript:void(0)" @click="topup()" class="btn btn-sm btn-success btn-block fw-600"><i class="fas fa-wallet mr-2"></i>Top Up</a>
                                </div>
                                <div class="col-12 mt-3" v-else>
                                    <router-link to="/profile" class="btn btn-sm btn-warning btn-block fw-600 text-white">Register your Phone!</router-link>
                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="col-12 text-center">
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card overflow-hidden subscribe-widget p-3 mb-3 rounded-xxl border-0 mb-5">
                    <div class="card-body d-block text-center">
                        <h2 class="text-grey-800  fw-900 mb-4 lh-3">Free Course Coupon</h2>
                        <div class="mt-3">
                            <router-link to="/coupon" class="p-2 w150 btn-current shadow-xs d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3 mx-2">Input Coupon</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TopUp @status="changeRegistered" :payment-status="paymentStatus"></TopUp>
    </div>
</template>

<script>
import axios from 'axios'
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import TopUp from './TopUp.vue'
export default ({
    name: 'RightSidebar',
    data(){
        return {
            media : process.env.VUE_APP_URL_CLOUD,
            user: null,
            isAdmin: false,
            options: {
                rewind : true,
                perPage : 3,
                autoplay : true,
                arrows : false,
                pagination: false,
                gap: '1rem'
            },
            isRegistered: false,
            loadWallet: true,
            wallet: {
                id: 0,
                balance: 0,
                balance_point: 0,
                user_id: 0,
                created_at: null,
                updated_at: null
            },
            config: {
                handler: this.onClickedOutside,
                middleware: this.middleware,
                events: ["dblclick", "click"]
            },
            paymentStatus: 0,
        }
    },
    props: {
        isSidebarActive: Boolean,
        personalEvent: Number
    },
    created() {
        this.getUser()
        this.getWallet();
    },
    mounted(){
        this.$on('toggleSidebarEvent', this.getToggleSidebarEvent)
    },
    components: {
        TopUp
    },
    watch: {
        personalEvent:function() {
            this.paymentStatus += 1;
            this.getWallet()
        }
    },
    methods: {
        changeRegistered(e){
            if(e == 200) {
                this.isRegistered = true
            }
        },
        onClickedOutside(e) {
            let el = e.srcElement.classList.contains('btn-toggle-sidebar')
            if(!el) {
                this.$emit('close-sidebar', true)
            }
        },
        middleware() {
            return true
        },
        topup(){
            this.$modal.show('topup-modal')
        },
        async getUser() {
            this.user = JSON.parse(localStorage.getItem('user'))
            this.isAdmin = this.user.role_id != 2
            // await axios.get(`${process.env.VUE_APP_URL_API}/user`, {
            //     headers: {
            //         Authorization: `Bearer ${localStorage.token}`
            //     }
            // }).then(res => {
            //     this.user = res.data
            //     this.isAdmin = res.data.role_id != 2
            // }).catch((err) => {
            //     console.log(err)
            // })
        },
        async onLogout() {
            await axios.get(`${process.env.VUE_APP_URL_API}/logout`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
                }
            }).then(() => {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                this.$router.push({name: 'BrowseNew'})
            }).catch((err) => console.error(err))
        },
        async getWallet(){
            this.loadWallet = true
            
            let user = JSON.parse(localStorage.getItem('user'))
            if(user.wallet){
                this.wallet = user.wallet
            }else{
                await axios.get(`${process.env.VUE_APP_URL_API}/account/balance`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.wallet = res.data.data
                    user.wallet = res.data.data
                    localStorage.setItem('user',JSON.stringify(user))
                }).catch((err) => console.error(err.response))
            }

            this.loadWallet = false
            
        },
        toggleSidebar(){
            this.isSidebarActive = !this.isSidebarActive
        },
        getToggleSidebarEvent(bool) {
			this.isSidebarActive = bool;
		}
    }
})
</script>
